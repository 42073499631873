<template>
      <footer class="w-full p-5 text-center bg-black mt-auto">
      <p class="neon">تابعونا على وسائل التواصل الاجتماعي</p>
      <div class="flex justify-center mt-2">
        <a href="https://www.facebook.com/profile.php?id=61567748524406" class="mx-2 text-purple-500 hover:text-white"><i class="fab fa-facebook-f"></i></a>
        <a href="https://wa.me/01212540775" class="mx-2 text-purple-500 hover:text-white"><i class="fab fa-whatsapp"></i></a>
        <a href="https://www.instagram.com/el.bosla1" class="mx-2 text-purple-500 hover:text-white"><i class="fab fa-instagram"></i></a>
        <a href="https://www.tiktok.com/@el.bosla" class="mx-2 text-purple-500 hover:text-white"><i class="fab fa-tiktok"></i></a>
        <a href="https://www.youtube.com/@el.bosla1" class="mx-2 text-purple-500 hover:text-white"><i class="fab fa-youtube"></i></a>
      </div>
    </footer>
</template>
<script>
  export default{
  name:"Media",
  }
</script>