<template>
  <div class="body">
     <header class="text-center py-4">
  <img src="@/assets/res/img/icons2/1000519005-removebg-preview.png" alt="">
 </header>

 <header class="text-center py-4">
  <h1 class="text-3xl font-bold">البوصلة - الجرافيك ديزاين</h1>
  <p>نقدم لك حلولاً إبداعية لتطوير هوية بصرية قوية وتصاميم سوشيال ميديا مبتكرة</p>
 </header>

 <!-- مقدمة -->
 <section class="text-center py-8 px-4">
  <h2 class="text-2xl font-bold mb-4">لماذا تحتاج إلى هوية بصرية قوية وتصاميم سوشيال ميديا احترافية؟</h2>
  <p class="mb-4">الهوية البصرية هي عامل رئيسي في بناء العلامة التجارية، وهي ما يميز نشاطك التجاري عن المنافسين. من خلال تصاميم مبتكرة وجذابة، نساعدك على الوصول لجمهورك المستهدف وبناء تواصل أقوى معهم.</p>
 </section>

 <!-- خدماتنا -->
 <section class="text-center py-8 px-4">
  <h2 class="text-2xl font-bold mb-4">خدماتنا في الجرافيك ديزاين</h2>
     <div class="flex flex-col md:flex-row md:space-x-8 items-center space-y-4 md:space-y-0">
   <div class="text-center p-4 bg-black rounded-lg">
    <img alt="تصميم الهوية" class="mx-auto mb-2" src="@/assets/res/img/icons2/1000518773-removebg-preview.png" width="260" />
    <h3 class="text-xl font-semibold mb-2">تطوير الهوية البصرية</h3>
    <p>إنشاء هوية بصرية متكاملة تشمل الألوان والخطوط والتصاميم التي تعبر عن علامتك.</p>
   </div>
   <div class="text-center p-4 bg-black rounded-lg">
    <img alt="تصاميم السوشيال ميديا" class="mx-auto mb-2" 
    src="@/assets/res/img/icons2/1000519015-removebg-preview.png" width="260" />
    <h3 class="text-xl font-semibold mb-2">تصاميم السوشيال ميديا</h3>
    <p>تصاميم جذابة ومخصصة لمنصات التواصل الاجتماعي، مصممة لتجذب التفاعل.</p>
   </div>


      <div class="text-center p-4 bg-black rounded-lg">
       <img alt="تصميم شعار" class="mx-auto mb-2" src="@/assets/res/img/icons2/1000519011-removebg-preview.png" width="230" />
       <h3 class="text-xl font-semibold mb-2">تصميم الشعار (Logo)</h3>
       <p>تصاميم شعارات فريدة تعكس هوية العلامة التجارية وتكون عالقة في أذهان الجمهور.</p>
      </div>
  </div>
 </section>

 <!-- لماذا نحن -->
 <section class="text-center py-8 px-4">
  <h2 class="text-2xl font-bold mb-4">لماذا اختيار البوصلة؟</h2>
  <div class="space-y-4">
   <div class="bg-white text-black py-2 px-4 rounded">فريق ذو خبرة طويلة في تصميم الهوية البصرية</div>
   <div class="bg-white text-black py-2 px-4 rounded">ابتكار وحلول مخصصة لكل عميل</div>
   <div class="bg-white text-black py-2 px-4 rounded">نتائج احترافية تعكس هوية علامتك بدقة</div>
  </div>
 </section>

 <!-- Footer -->
 

    
  </div>
</template>


<style scoped>
.body {
   font-family: 'Cairo', sans-serif;
   background: linear-gradient(90deg, #3333cc, #330099);
   user-select: none;
  }
  img {
   border-radius: 7px;
  }
</style>