<template>
  <div class="body">
     <header class="text-center py-4">
  <img src="@/assets/res/img/icons/Web Developer.png" alt="">
 </header>

 <!-- مقدمة -->
 <section class="text-center py-8 px-4">
  <h1 class="text-3xl font-bold mb-2">البوصلة - تصميم وبرمجة المواقع الإلكترونية</h1>
  <p class="mb-4">نحن نقدم حلول مبتكرة وفعالة لتطوير مواقع إلكترونية عصرية وجذابة، تناسب احتياجات عملك وتساعدك على التواصل مع عملائك بشكل أفضل.</p>
 </section>

 <!-- لماذا تحتاج موقع إلكتروني؟ -->
 <section class="bg-white text-black text-center py-8 px-4 rounded-lg mb-4">
  <h2 class="text-2xl font-bold mb-4">لماذا تحتاج إلى موقع إلكتروني أو متجر إلكتروني؟</h2>
  <p class="mb-4">في عالم اليوم، يعتبر الموقع الإلكتروني واجهة أساسية لأي شركة أو نشاط تجاري. فهو يساعدك على الوصول إلى جمهور أوسع ويعزز من مصداقيتك المهنية ويزيد من فرص التفاعل مع عملائك.</p>
  <p class="mb-4">كما يسهل عملية البيع والتواصل ويقدم تجربة فريدة للعملاء، مما يرفع من ولائهم لعلامتك التجارية.</p>
  <img alt="صورة متجر إلكتروني" class="mx-auto mt-4" src="@/assets/res/img/icons/8.jpg" width="300" style="border-radius: 10px" />
 </section>

 <!-- خدماتنا -->
 <section class="text-center py-8 px-4">
  <h2 class="text-2xl font-bold mb-4">خدماتنا</h2>
  <div class="flex flex-col md:flex-row md:space-x-8 items-center space-y-4 md:space-y-0">
   <div class="text-center p-4 bg-black rounded-lg">
    <img alt="تصميم واجهات" class="mx-auto mb-2" src="@/assets/res/img/icons2/1000518859-removebg-preview.png" width="370" />
    <h3 class="text-xl font-semibold mb-2">تصميم واجهات مستخدم</h3>
    <p>تصميمات عصرية وجذابة تضمن تجربة مستخدم سلسة وسهلة التنقل.</p>
   </div>
   <div class="text-center p-4 bg-black rounded-lg">
    <img alt="برمجة مواقع" class="mx-auto mb-2" src="@/assets/res/img/icons2/Custom-software-development-it-magnet-soft.png" width="240" />
    <h3 class="text-xl font-semibold mb-2">برمجة مواقع متكاملة</h3>
    <p>تطوير مواقع إلكترونية باستخدام أحدث التقنيات لتلبية كافة احتياجات عملك.</p>
   </div>
   <div class="text-center p-4 bg-black rounded-lg">
    <img alt="تجربة المستخدم" class="mx-auto mb-2" src="@/assets/res/img/icons2/1_FUgSi8RZKjq5rAGbKzATqw-removebg-preview.png" width="240" />
    <h3 class="text-xl font-semibold mb-2">تحسين تجربة المستخدم</h3>
    <p>تحليل وتطوير التجربة لتحسين فعالية الموقع وزيادة التفاعل مع العملاء.</p>
   </div>
  </div>
 </section>

 <!-- لماذا نحن -->
 <section class="text-center py-8 px-4">
  <h2 class="text-2xl font-bold mb-4">لماذا البوصلة؟</h2>
  <div class="space-y-4">
   <div class="bg-white text-black py-2 px-4 rounded">خبرة تمتد لأكثر من 2 سنوات</div>
   <div class="bg-white text-black py-2 px-4 rounded">نتائج احترافية بأقل تكلفة</div>
   <div class="bg-white text-black py-2 px-4 rounded">دعم فني على مدار الساعة</div>
   <div class="bg-white text-black py-2 px-4 rounded">تجربة مستخدم مميزة</div>
  </div>
 </section>

  </div>
</template>
<style scoped>
.body {
   font-family: 'Cairo', sans-serif;
   background: linear-gradient(90deg, #3333cc, #330099);
   user-select: none;
  }
  img{
   border-radius: 7px;
  }
</style>