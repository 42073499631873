<template>
  <Contact />
</template>
 <script>
   import Contact from"../components/Contact.vue";
   
   export default {
       name:"ContactV",
       components:{
         Contact,
       }
     }
 </script>
