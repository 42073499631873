<template>
  <Content />
</template>
<script>
  import Content from "../components/Content.vue";
  export default{
    name:"ContentV",
    components:{
       Content
    },
  }
</script>