<template>
  <Programming />
</template>
<script>
  import Programming from "../components/Programming.vue";
  export default{
    name:"ProgrammingV",
    components:{
       Programming
    },
  }
</script>