<template>
  <Design />
</template>
<script>
  import Design from "../components/Design.vue";
  export default{
    name:"DesignV",
    components:{
       Design,
    },
  }
</script>