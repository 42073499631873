<template>
  <Home />
  
</template>

<script>
  import Home from"../components/Home.vue";
  export default{
    name:"HomeV",
    components:{
     Home,
     
    }
  }
</script>