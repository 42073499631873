<template>
  <Marketing />
</template>
<script>
  import Marketing from "../components/Marketing.vue";
  export default{
    name:"MarketingV",
    components:{
       Marketing
    },
  }
</script>